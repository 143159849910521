import React from 'react'
import Showdown from 'showdown'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'

class solutionCardSet extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    const converter = new Showdown.Converter()
    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` }>
        <MDBContainer>
          {(this.props.data.title || this.props.data.subtitle) && (
            <MDBRow>
              <MDBCol md="12">
                {this.props.data.title && (
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {this.props.data.title}
                  </h2>
                )}
                {this.props.data.subtitle && (
                  <div
                    className="text-medium richtext"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.subtitle), }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow className="pt-5">
            {this.props.data.feature.map((features, index) => (
              <MDBCol lg="4" md="6" className="pb-card d-flex" key={index}>
                <MDBCard className="flex-fill">
                  {features.link ? (
                    <>
                      {features.link.substring(0, 4) != 'http' && features.link.substring(0, 8) != '/static/' ? (
                        <Link to={features.link}>
                          {features.image && (
                            <GatsbyImage
                              image={features.image.childImageSharp.gatsbyImageData}
                              alt={features.alttext}
                              className="rounded-top drop-shadow"
                            />
                          )}
                        </Link>
                      ) : (
                        <a href={features.link} target="_blank" rel="noopener">
                          <>
                            {features.image && (
                              <GatsbyImage
                                image={features.image.childImageSharp.gatsbyImageData}
                                alt={features.alttext}
                                className="rounded-top drop-shadow"
                              />
                            )}
                          </>
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      {features.image && (
                        <GatsbyImage
                          image={features.image.childImageSharp.gatsbyImageData}
                          alt={features.alttext}
                          className="rounded-top drop-shadow"
                        />
                      )}
                    </>
                  )}

                  <MDBCardBody>
                    <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
                      <h3 className="font-alt font-w-700 mt-2 letter-spacing-1 title-small title-xs-medium">
                        {features.title}
                      </h3>
                      {features.subtitle != '' && features.subtitle != null && (
                        <>
                          <div
                            className="font-w-400 text-medium text-lg-large mt-2"
                            dangerouslySetInnerHTML={{
                              __html: features.subtitle,
                            }}
                          />
                        </>
                      )}
                    </div>
                    <hr />
                    <MDBCardText className="font-w-400 text-card-small">
                      {features.link && 
                        <Link to={features.link} className="effect">
                          {features.description}
                        </Link>
                      }
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionCardSet
