import React from 'react'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

class solutionTextImageBelow extends React.Component {
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const iconColour = this.props.sectionClass.includes('bg-blue-gradient') ? 'ico-color-white' : 'ico-color-blue'
    const converter = new Showdown.Converter()
    const Heading = this.props.data.htag ? this.props.data.htag : 'h2'
    const headingClass = this.props.data.htag === 'h3' ? 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mb-2' : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3'

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` }>
        <MDBContainer>
        <MDBRow>
            <MDBCol md="12">
              <Heading className={`${headingClass} ${textClass}`}>
                {this.props.data.title}
              </Heading>
              <p className={`font-alt text-medium font-w-700 letter-spacing-1 title-xs-medium title-medium ${textClass}`}>
                {this.props.data.subtitle}
              </p>


              <div
              className={`text-medium divlink ${textClass}`}
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.description) }}
            />

              {(this.props.data.link || this.props.data.document) && (
                <>
                  {this.props.data.link ? (
                    <Link
                      to={this.props.data.link}
                      className={`nav-link btn btn-mdb-color btn-sm-block mt-3 ${textClass}`}
                    >
                      <FontAwesomeIcon icon={faChevronRight} className={`mr-2 ${iconColour}`} />
                      {this.props.data.linktext}
                    </Link>
                  ) : (
                    <a
                      href={this.props.data.document.publicURL}
                      className={`nav-link btn btn-mdb-color btn-sm-block mt-3 ${textClass}`}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faDownload} className={`mr-2 ${iconColour}`} />
                      {this.props.data.linktext}
                    </a>
                  )}
                </>
              )}
              </MDBCol>
            </MDBRow>

          <MDBRow>
            <MDBCol lg="12 pt-5 text-center">
              <GatsbyImage
                image={this.props.data.image.childImageSharp.gatsbyImageData}
                alt={this.props.data.alttext}
                className="alt-bg-image rounded "
              />
            </MDBCol>
            </MDBRow>
           
              {this.props.data.feature && this.props.data.feature.length >= 1 && (
                <div>
                  {this.props.data.feature.map((features, index) => (
                    <>
                      <MDBRow className="pb-4">
                        <MDBCol md="2">
                          {features.icon ? (
                          <FontAwesomeIcon
                            icon={['fat', features.icon]}
                            size="2x"
                            className="pl-3"
                          />
                          ) : (
                          <GatsbyImage
                            image={features.image.childImageSharp.gatsbyImageData}
                            alt={features.alttext}
                            className="alt-bg-image"
                          />  
                          )
                        }
                        </MDBCol>

                        <MDBCol md="10">
                          <span className="text-medium font-w-700">
                            {features.title}
                          </span>
                          <span className="text-medium text-gray-dark">
                            {features.subtitle}
                            <br />
                          </span>
                          <span className="text-medium text-gray-dark">
                            {features.link ? (
                              <Link to={features.link} className="effect">
                                {features.description}
                              </Link>
                            ) : (
                              features.description
                            )}
                          </span>
                        </MDBCol>
                      </MDBRow>
                    </>
                  ))}
                </div>
              )}
        </MDBContainer>
      </section>
    )
  }
}

export default solutionTextImageBelow
