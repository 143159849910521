import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'

class solutionFeatures extends React.Component {
  render() {
    return (
      <section className="bg-blue-gradient">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" className="pr-5">
              <h2 className="text-white font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pt-5">
                {this.props.data.title}
              </h2>
              <div className="text-white font-alt font-w-400 letter-spacing-1 title-xs-medium title-small mb-5">
                {this.props.data.subtitle}
              </div>
              {this.props.data.feature.map((features, index) => (
                <>
                <h3 className="text-white font-alt font-w-700 letter-spacing-1 title-xs-medium title-small">{features.title}</h3>
                <p className="text-medium text-white pb-5">{features.description}</p>
                </>
              )) 
              }              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionFeatures
